footer{
  svg{
    transform: rotate(95deg);
    path{
      fill: var(--yellow)
    }
    @media (max-width: $breakpoint-m){
      display: none;
    }
  }

  li{
    @media (max-width: $breakpoint-m){
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  li{
    @media (max-width: $breakpoint-m){
      margin-top: 16px;
    }
  }

}
