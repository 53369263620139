.no\@m{
  @media (max-width: $breakpoint-m){
    display: none!important;
  }
}
.no\@dkt{
  @media (min-width: $breakpoint-m){
    display: none!important;
  }
}

//Margins && paddings
.m-xl{ margin: 160px;
  @media (max-width: $breakpoint-m){margin: 32px;}}
.m-l{ margin: 80px;
  @media (max-width: $breakpoint-m){margin: 24px;}}
.m-m{ margin: 40px;
  @media (max-width: $breakpoint-m){margin: 16px;}}
.m-s{ margin: 24px;
  @media (max-width: $breakpoint-m){margin: 8px;}}
.m-xs{ margin: 16px;
  @media (max-width: $breakpoint-m){margin: 6px;}}
.m-xxs{ margin: 8px;
  @media (max-width: $breakpoint-m){margin: 4px;}}

.mt-xl{ margin-top: 160px;
  @media (max-width: $breakpoint-m){margin-top: 32px;}}
.mt-l{ margin-top: 80px;
  @media (max-width: $breakpoint-m){margin-top: 24px;}}
.mt-m{ margin-top: 40px;
  @media (max-width: $breakpoint-m){margin-top: 16px;}}
.mt-s{ margin-top: 24px;
  @media (max-width: $breakpoint-m){margin-top: 8px;}}
.mt-xs{ margin-top: 16px;
  @media (max-width: $breakpoint-m){margin-top: 6px;}}
.mt-xxs{ margin-top: 8px;
  @media (max-width: $breakpoint-m){margin-top: 4px;}}

.mb-xl{ margin-bottom: 160px;
  @media (max-width: $breakpoint-m){margin-bottom: 32px;}}
.mb-l { margin-bottom: 80px;
  @media (max-width: $breakpoint-m){margin-bottom: 24px;}}
.mb-m { margin-bottom: 40px;
  @media (max-width: $breakpoint-m){margin-bottom: 16px;}}
.mb-s { margin-bottom: 24px;
  @media (max-width: $breakpoint-m){margin-bottom: 8px;}}
.mb-xs { margin-bottom: 16px;
  @media (max-width: $breakpoint-m){margin-bottom: 6px;}}
.mb-xxs { margin-bottom: 8px;
  @media (max-width: $breakpoint-m){margin-bottom: 4px;}}

.ml-xl{ margin-left: 160px;
  @media (max-width: $breakpoint-m){margin-left: 32px;}}
.ml-l { margin-left: 80px;
  @media (max-width: $breakpoint-m){margin-left: 24px;}}
.ml-m{ margin-left: 40px;
  @media (max-width: $breakpoint-m){margin-left: 16px;}}
.ml-s{ margin-left: 24px;
  @media (max-width: $breakpoint-m){margin-left: 8px;}}
.ml-xs { margin-left: 16px;
  @media (max-width: $breakpoint-m){margin-left: 6px;}}
.ml-xxs { margin-left: 8px;
  @media (max-width: $breakpoint-m){margin-left: 4px;}}

.mr-xl{ margin-right: 160px;
  @media (max-width: $breakpoint-m){margin-right: 32px;}}
.mr-l { margin-right: 80px;
  @media (max-width: $breakpoint-m){margin-right: 24px;}}
.mr-m{ margin-right: 40px;
  @media (max-width: $breakpoint-m){margin-right: 16px;}}
.mr-s{ margin-right: 24px;
  @media (max-width: $breakpoint-m){margin-right: 8px;}}
.mr-xs { margin-right: 16px;
  @media (max-width: $breakpoint-m){margin-right: 6px;}}
.mr-xxs { margin-right: 8px;
  @media (max-width: $breakpoint-m){margin-right: 4px;}}

.p-xl{ padding: 160px;
  @media (max-width: $breakpoint-m){padding: 32px;}}
.p-l{ padding: 80px;
  @media (max-width: $breakpoint-m){padding: 24px;}}
.p-m{ padding: 40px;
  @media (max-width: $breakpoint-m){padding: 16px;}}
.p-s{ padding: 24px;
  @media (max-width: $breakpoint-m){padding: 8px;}}
.p-xs{ padding: 16px;
  @media (max-width: $breakpoint-m){padding: 6px;}}
.p-xxs{ padding: 8px;
  @media (max-width: $breakpoint-m){padding: 4px;}}

.pt-xl{ padding-top: 160px;
  @media (max-width: $breakpoint-m){padding-top: 32px;}}
.pt-l{ padding-top: 80px;
  @media (max-width: $breakpoint-m){padding-top: 24px;}}
.pt-m{ padding-top: 40px;
  @media (max-width: $breakpoint-m){padding-top: 16px;}}
.pt-s{ padding-top: 24px;
  @media (max-width: $breakpoint-m){padding-top: 8px;}}
.pt-xs{ padding-top: 16px;
  @media (max-width: $breakpoint-m){padding-top: 6px;}}
.pt-xxs{ padding-top: 8px;
  @media (max-width: $breakpoint-m){padding-top: 4px;}}

.pb-xl{ padding-bottom: 160px;
  @media (max-width: $breakpoint-m){padding-bottom: 32px;}}
.pb-l { padding-bottom: 80px;
  @media (max-width: $breakpoint-m){padding-bottom: 24px;}}
.pb-m { padding-bottom: 40px;
  @media (max-width: $breakpoint-m){padding-bottom: 16px;}}
.pb-s { padding-bottom: 24px;
  @media (max-width: $breakpoint-m){padding-bottom: 8px;}}
.pb-xs { padding-bottom: 16px;
  @media (max-width: $breakpoint-m){padding-bottom: 6px;}}
.pb-xxs { padding-bottom: 8px;
  @media (max-width: $breakpoint-m){padding-bottom: 4px;}}

.mt-24{
  margin-top: 24px;
}
.mb-20{
  margin-bottom: 20px;
}
.no-m{
  margin: 0;
}
.no-ml{
  margin-left: 0;
}
.no-p{
  padding: 0;
}




//Helpers
.inline {
  display: inline;
  align-items: center;
}
.inline-block{
  display: inline-block;
}
.text-center{
  text-align: center;
}
.centered{
  display: block;
  text-align: center;
}
.line-trough{
  text-decoration: line-through;
}
.auto{ margin: auto; }
.oauto{margin: 0 auto; }
.flex{ display:flex; }
.dl-grid{ display: grid;}
.flex-justify{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center{
  align-items: center;
  justify-content: center;
  display: flex;
}
.flex-end{
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
}
.inflex{
  display: inline-flex;
}
.v-center{
  align-content: center;
  display: grid;
}
.jc-center{
  justify-content: center;
}
.ai-center{
  align-items: center;
}
.underline{
  text-decoration: underline;
}
.underline-hover{
  &:hover{
    text-decoration: underline;
  }
}
.no-b{
  border: none!important;
}
.no-s{
  box-shadow: none!important;
}
.relative{
  position: relative;
}
.vh100{
  min-height: calc(100vh - #{$header-height});
}
.cap{
  text-transform: capitalize;
}
.ov-h{
  overflow: hidden;
}
.dl-b{
  display: block;
}

.br-s{
  border-radius: 8px;
}
.br-m{
  border-radius: 16px;
}
.br-l{
  border-radius: 24px;
}
.br-xl{
  border-radius: 32px;
}

.h-auto{
  height: auto!important;
}
.mx-h-300{
  max-height: 300px;
}
.mx-h-500{
  max-height: 500px;
}
.mx-h-700{
  max-height: 700px;
}

.mx-w-50{
  max-width: 50%!important;
}
.mx-w-80{
  max-width: 80%!important;
}
//Cards
.card-16{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

//Images
.img-fit{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.img-contain{
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.w-90{
  width: 90%;
}
.w-100{
  width: 100%;
}
.max-h-m{
  max-height: 340px;
}
.abs-b-r{
  position: absolute;
  bottom: 0;
  right: 0;
}

//Blocks
//LAYOUT
.block{
  width: 100%;
  padding: 120px 0 80px 0;
  display: block;
  @media (max-width: $breakpoint-m){
    padding: 40px 0;
  }
}





//EFFECTS
.drawing-icon{
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 0;
  svg{
    position: absolute;
    width: 140%;
    height: auto;
    fill: none;
    z-index: -1;
    align-self: flex-end;
    margin-left: 20%;
    top: -50px;
    @media (max-width: $breakpoint-m){
      top: -30px;
    }
    path {
      stroke-width: 24px;
      stroke-dasharray: 2280;
      stroke-dashoffset: 2280;
      animation: dash 650ms ease-in-out forwards;
      stroke: #fecb4a;
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.list-square{
  list-style: inside;
  list-style-type: square;
}
.has-mleft{
  margin-left: 16.6667%;
}
.text-bold {
  @extend .alt-font;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 8px;
}
.as-p{
  font-size: 18px;
  margin-bottom: 24px;
  margin-top: 12px;
  line-height: 32.4px;
}