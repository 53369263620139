
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/** SHARP GROTESK **/
@font-face {
  font-family: 'Sharp Grotesk Book';
  src:
  url('~/public/fonts/sharp-grotesk-book-15-regular.woff2') format('woff2'),
  url('~/public/fonts/sharp-grotesk-book-15-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Grotesk SmBold';
  src:
  url('~/public/fonts/sharp-grotesk-smbold-20-regular.woff2') format('woff2'),
  url('~/public/fonts/sharp-grotesk-smbold-20-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Sharp Grotesk Medium';
  src:
  url('~/public/fonts/sharp-grotesk-medium-25-regular.woff2') format('woff2'),
  url('~/public/fonts/sharp-grotesk-medium-25-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body{
  font-size: 15px;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-weight: 300;
  color: var(--body-type);
  -webkit-font-smoothing: antialiased;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Sharp Grotesk Medium", Open Sans,sans-serif;
  &::first-letter{
    text-transform: uppercase;
  }
}
h1 {
  font-size: 58px;
  line-height: 62px;
  padding: 0;
  @media (max-width: $breakpoint-m){
    font-size:48px;
  }
}
h2 {
  font-size: 48px;
  @media (max-width: $breakpoint-m){
    font-size:38px;
  }
}
h3 {
    font-size: 32px;
    padding: 0;
    margin: 0;
    @media (max-width: $breakpoint-m){
      font-size:28px;
    }
}
h4 {
  font-size: 28px;
  @media (max-width: $breakpoint-m) {
    font-size: 24px;
    line-height: 26px;
  }
}
h5 {
  font-size: 24px;
  line-height: 28px;
  @media (max-width: $breakpoint-m) {
    font-size: 20px;
    line-height: 22px;
  }
}
h6 {
  font-size: 18px;
  line-height: 24px;
  @media (max-width: $breakpoint-m) {
    font-size: 14px;
    line-height: 20px;
  }
}

p{
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 24px;
  margin-top: 12px;
  line-height: 32.4px;
  &::first-letter{
    text-transform: uppercase;
  }
}
p.big-text{
  font-weight: normal;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 38.4px;
  margin-bottom: 24px;
  margin-top: 12px;
  &::first-letter{
    text-transform: uppercase;
  }
}
p.small-text{
  font-size: 12px;
  font-weight: 500;
  line-height: 20.4px;
}
.main-font{
  font-family: "Sharp Grotesk Medium", Open Sans,sans-serif;
}
.alt-font{
  font-family: "Sharp Grotesk SmBold", Open Sans,sans-serif;
}
.bold{
  font-weight: bold;
}
.f-32{
  font-size: 32px;
}
