.nda{
  top: 16px;
  left: 24px;
  position: absolute;
}
.nda-icon{
  width: 80px;
  height: 40px;
  border: 2px solid var(--cyan);
  display: inline-flex;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  color: var(--cyan);
  align-items: center;
  justify-content: center;
  svg{
    margin-right: 4px;
    path{
      fill: var(--cyan);
    }
  }
}

.nda-text{
  margin-left: 12px;
  align-items: center;
  display: grid;
  color: #69fdef;
  display: initial;
  font-size: 12px;
}
.nda-text-title{
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.8px;
}
.nda-text-label{
  align-items: center;
  margin-top: 4px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  opacity: 0.4;
  font-size: 11px;
  font-weight: 800;
}
