
.accordion {
  img, video{
    border-radius: 6px;
    box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3);
  }
  video{
    max-width: 900px!important;
    padding: 0;
    margin: 64px auto;
    display: block;
    --gutter: 16px;
    padding: var(--gutter);
    border-radius: 36px;
    background: #f6f9fc;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35);
  }
}

.accordion .label{
  color: var(--grey);
}

.accordion__item + .accordion__item {
    /*border-top: 1px solid var(--soft-grey-bg);*/
}

.accordion__button {
    cursor: pointer;
    padding: 24px;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 4px;
    box-shadow: 0 2px 5px -1px rgba(50,50,93,0.25), 0 1px 3px -1px rgba(0,0,0,0.3);
    margin-top: 16px;
    transition: all .6s ease 0s;
}

.accordion__button:hover {
  background: #eff2f7;
  transition: all .6s ease 0s;
}
.accordion__button:focus {
    border: none !important;
    outline: none !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.accordion__button .label:after {
  display: inline-block;
  height: 8px;
  width: 8px;
  right: 0;
  float: right;
  transition: all .06s ease-in;
  position: relative;
  content: "\d7";
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  top: 0;
  transform-origin: center;
  line-height: 8px;
  transform: rotate(-45deg);
}
.accordion__button[aria-expanded='true']{
  background: #eff2f7;
}
.accordion__button[aria-expanded='true'] .label{
    color: var(--typo);
}
.accordion__button[aria-expanded='true'] .label::after,
.accordion__button[aria-selected='true'] .label::after {
    transform: rotate(0deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 24px 24px 56px 24px;
    animation: fadein 0.35s ease-in;
}

.accordion__heading{
  @extend .container;
  @extend .container-xs
}
/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
