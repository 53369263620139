.banner{
  background: var(--black);
  color: #fff;
  padding: 40px 0px;
  @media (max-width: $breakpoint-m){
    .container{
      display: grid;
      grid-gap: 32px;
    }
  }
}
