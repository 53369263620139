.header{
  height: $header-height;
  font-size: 16px;
}
.header-logo{
  font-family: "Sharp Grotesk Medium", Open Sans,sans-serif;
}
.header-menu{}
.header-menu-nav{
  @media (max-width: $breakpoint-m){
    flex-wrap: wrap;
    justify-content: center;
  }
}
.header-menu-nav-item{
  margin:0 8px;
  font-weight: bold;
  &:hover{
    //text-decoration: underline;
  }
}
.section-footer .header-menu-nav-item {
  &:hover{
    text-decoration: underline;
  }
}

.badge{
  background: #00f;
  font-size: 10px;
  padding: 1px 4px;
  line-height: 12px;
  color: #fff;
  display: inline-flex;
  top: -6px;
  position: relative;
}

.pill{
  border: 2px solid var(--grey-neutral);;
  border-radius: 8px;
  padding: 6px 16px 6px 12px;
  font-weight: 700;
  font-size: 14px;
  background: #fff;
  transition: all 0.2s ease-in;
  span{
    display: flex;
    align-items: center;
    &:before{
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      //background: #0e17ff;
      background: #00da00;
      animation: blink 0.5s linear infinite;
    }
  }
  &:hover{
    cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>💌</text></svg>") 16 0,auto;
    background: var(--grey-neutral);
    border: 2px solid rgb(237 239 236);
  }
}

.button-nav{
  padding: 4px 8px;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  &:hover{
    color: var(--heading);
    background: var(--grey-neutral);
  }
  
}
.selected{
  color: var(--heading);
  background: var(--grey-neutral);
}