
//https://codepen.io/maoberlehner/pen/aWarZO

.container{
  position: relative;
  align-self: center;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
}
.container-xl{
  max-width: 1340px;
}
.container-m{
  max-width: 1016px;
}
.container-s{
  max-width: 800px;
}
.container-xs{
  max-width: 720px; //630px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-bottom: 40px;
  @media (max-width: $breakpoint-m){
    grid-gap: 48px 24px;
  }
}
.width-1\/12 {
  grid-column: span 1;
}

.width-2\/12 {
  grid-column: span 2;
}

.width-3\/12 {
  grid-column: span 3;
}

.width-4\/12 {
  grid-column: span 4;
}

.width-5\/12 {
  grid-column: span 5;
}

.width-6\/12 {
  grid-column: span 6;
}

.width-7\/12 {
  grid-column: span 7;
}

.width-8\/12 {
  grid-column: span 8;
}

.width-9\/12 {
  grid-column: span 9;
}

.width-10\/12 {
  grid-column: span 10;
}

.width-11\/12 {
  grid-column: span 11;
}

.width-12\/12 {
  grid-column: span 12;
}

.width-1\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 1;
  }
}

.width-2\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 2;
  }
}

.width-3\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 3;
  }
}

.width-4\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 4;
  }
}

.width-5\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 5;
  }
}

.width-6\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 6;
  }
}

.width-7\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 7;
  }
}

.width-8\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 8;
  }
}

.width-9\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 9;
  }
}

.width-10\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 10;
  }
}

.width-11\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 11;
  }
}

.width-12\/12\@m {
  @media (max-width: $breakpoint-m) {
    grid-column: span 12;
  }
}
