
//SECTION
.section-latest-projects{}

.latest-project-story{
  display: flex;
  align-items: center;
  grid-column-start: 3;
  grid-column-end: 12;
  @media (max-width: $breakpoint-m){
    grid-column-start: 1;
    grid-column-end: 12;
  }
}
.latest-project-story-content span{
  color: var(--grey);
  line-height: 1.4;
}


//SECTION CASES
$item-height:480px;

.section-cases{}
.section-cases-grid{}

.section-cases-item{
  height: $item-height;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 40px;
  overflow: hidden;
  @media (max-width: $breakpoint-m){
    max-height: 380px;
    height: auto;
    picture{
      display:none;
    }
  }
}
.section-cases-item-title{
  margin-bottom: 24px;
}
.title-small{
  font-size: 26px;
}
.section-cases-item-label{
  font-size: 16px;
  line-height: 20px;
  color: var(--grey);
}
.section-cases-item-description{
  margin-top: 0px;
}

.section-cases-item picture img {
    max-height: 600px;
    @media (max-width: $breakpoint-m){
      max-height: 380px;
    }
}

//ARROW EFFECT
.section-cases-item-title,
.latest-project-title{
  margin-bottom: 24px;
  transition: all .2s ease 0s;
  &:after {
    content: "→";
    font-weight: lighter;
    transition: all .2s ease 0s;
    position: absolute;
    margin: 0 0 0 -30px;
    opacity: 0;
    cursor: pointer;
  }
}
a.section-cases-item{
  cursor: pointer;
  transition: all .6s ease 0s;
  transform: scale(.988);
  &:hover .section-cases-item-title {
      margin-left: -10px;
      transition: all .6s ease 0s;
      &:after {
          opacity: 1;
          transition: all .6s ease 0s;
          margin-left: 0;
      }
  }
}
.latest-project-title:hover{
  margin-left: -10px;
  text-decoration: underline;
  transition: all .6s ease 0s;
  &:after{
    opacity: 1;
    transition: all .6s ease 0s;
    margin-left: 0;
  }
}
.arrow-external:hover .section-cases-item-title:after {
    transform: rotate(-90deg);
    transition: all .2s ease 0s;
}

a.section-cases-item:hover {
    box-shadow: 0 13px 27px -5px rgba(50,50,93,0.12), 0 8px 16px -8px rgba(0,0,0,0.1);
    transition: all 0.2s ease-in;
    transform: translateY(-2px);
    transition: all .4s ease 0s;
    transform: scale(1.02);
}

//SECTION
.side-project-visual{
  height: 650px;
}

//CASES
.case-favbox{
  grid-row: span 2;
  height: auto;
  background-color: rgb(218 223 224);
  picture{
    margin-top: 110px;
    margin-left: -100px;
    img:nth-child(1){
      margin-left: -20px;
    }
    img:nth-child(2){
      margin-top: -80px;
    }
  }
}
.case-companion{
  grid-row: span 2;
  height: auto;
  background-color: #e2e9eb;
}
.case-guia-hedonista{
  grid-row: span 2;
  height: auto;
  background: #f8f4f2;
}
.case-keepkee{
  grid-row: span 2;
  height: auto;
  background: #dddfe8;
}
.case-hooptap{
  background: #f7f7fb;
}
.case-dulcesol{
  background: rgb(253 245 224);
}
.case-movibeta{
  //background: #eff2f7;
  background: #e7edfd;
  grid-row: span 2;
  height: auto;
  picture{
    margin-top: 0px;
    max-height: 600px;
    img{
      max-height: 870px !important;
      object-fit: contain;
      height: 870px;
      margin-left: -40px;
      margin-top: -25px;
    }
  }
}

.case-mindset{
  background-color: #E9F8F8;
}
.bg-koaHealth{
  background-color: #fbf4ef;
}
.case-koa{
  background-color: #fbf4ef;
}

.bg-fou{
  background-color: #fbf4ef;
}
.fou-vid{
  width: 375px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  clip-path: inset(2px 2px);
}

