.page-about{
  //background-color: rgb(255, 247, 239);
  background-image: url('~/public/img/bg.svg');
  background-position: top center;
  background-repeat: no-repeat;
}


//SECTION
.section-principles{
  background: var(--grey-neutral);
  padding: 120px 0;
  color: var(--black);
  position: relative;
  overflow: hidden;
  .section-title-header,
  .item-headline {
    color: var(--black);
  }
  svg{
    position: absolute;
    bottom: 0;
    right: 0;
    //transform: rotate(180deg);
    fill:#f4f0eb;
  }
}
.section-principles .grid__item {
  color: var(--black);
  background: var(--grey-neutral);
  padding: 64px 40px;
  &:hover{
    -webkit-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    transform: rotate(-1deg);
    transition: all .2s ease 0s;
  }
  @extend .br-xl;
}

h5.item-headline.alt-font span {
  font-size: 40px;
  margin-bottom: 24px;
  width: 72px;
  height: 72px;
  background:rgb(237 239 236);
  display: block;
  text-align: center;
  line-height: 72px;
  border-radius: 100px;
}

.me{
  position: absolute;
  top: -110px;
  transform: rotate(-8deg);
  right: 2%;
  z-index: 0;
  //@extend .no\@m;
  @media (max-width: $breakpoint-m){
    top: -48px;
    max-height: 120px;
  }
}