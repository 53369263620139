input {
  padding: 6px 14px;
  min-width: 240px;
  outline: 0;
  border: 2px solid;
  color: var(--dark-grey);
  border-radius: 6px 0px 0px 6px;
  font-size: 15px;
  line-height: unset;
  height: 40px;
  border-right: 0;
}
button{
  background-color: var(--dark-grey);
  border: 2px solid var(--dark-grey);
  color: #ffffff;
  padding: 6px 14px;
  min-width: 180px;
  height: 40px;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
  &:hover{
    background-color: #fff;
    color: var(--dark-grey);
    transition: all 0.2s ease-in;
  }
}
button span{
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
}
