.message{
  padding: 12px;
  font-size: 13px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.message.info{
    background: var(--soft-blue);
    color: hsla(216, 93%, 38%, 1);
}
.message.success {
    color: hsla(152, 58%, 28%, 1);
    background: hsla(152, 58%, 88%, 1);
}

.message.error {
    color: hsla(18, 78%, 26%, 1);
    background: hsla(9, 95%, 88%, 1);
}


//
.protected .message{
   max-width: 420px;
   margin: 32px auto 0px auto;
}
