.status{
  background: #fffffff0;
  padding: 8px 24px 8px 8px;
  border-radius: 4px;
  max-width: 480px;
  font-size: 12px;
  backdrop-filter: blur(4px);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px 0px, rgba(0, 0, 0, 0.08) 0px 5px 10px 0px;
  position: fixed;
  left: 40px;
  z-index: 10;
  line-height: 1.2;
  @media (max-width: $breakpoint-m){
    display: none;
  }
}
.profile-image-holder {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    position: relative;
    //border: 1px solid #eeedf2;
    border-radius: 50%;
    box-shadow: 0 4px 15px 0 rgba(40,44,53,.06), 0 2px 2px 0 rgba(40,44,53,.08);
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-image: url('~/public/img/me-cap.jpg');
    border-radius: 50%;
}
.profile-status:before {
    width: 16px;
    height: 16px;
    content: '';
    display: block;
    background: #fff;
    animation: none;
    z-index: 0;
    position: absolute;
    right: -1px;
    bottom: -1px;
    border-radius: 50%;
}
.profile-status:after {
    width: 12px;
    height: 12px;
    background: #00f;
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #fff;
    animation: blink 0.5s linear infinite;
    z-index: 1;
    display: block;
    content: '';
}
.profile-text-headline{
  margin-bottom: 4px;
  color: var(--black);
}
.profile-text-body{
  color: var(--typo);
  a{
    color: var(--dark-blue);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.show-true{
  transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
  bottom: 40px;
}
.show-false{
  transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
  bottom: -200px;
}

.close-icon{
  height: 8px;
  width: 8px;
  float: right;
  top: 8px;
  right: 8px;
  position: absolute;
  cursor: pointer;
  path{
    fill:#9da6b8;
  }
}
