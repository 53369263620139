.page-detail + .status{
  display: none!important;
}
.project-detail-content{
  @extend .project-content-in-bottom;
}
.project-detail-title {
  @extend .project-title-in;
  span {
    font-weight: normal;
    color: var(--grey);
  }
  .project-title{
    max-width: 80%;
    @media (max-width: $breakpoint-m){
      font-size: 32px;
      line-height: 40px;
    }
  }
}
.project-detail-text{
  @extend .project-title-in;
  @extend .mt-m;
}

.project-detail{
  ul {
      list-style: disc;
      padding-left: 24px;
  }
  li {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 16px;
      margin-top: 12px;
      line-height: 32.4px;
  }
  img, video {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    background-color: rgba(132, 132, 132, 0.1);
    color: var(--black);
  }
  b, bold, .text-bold {
    @extend .alt-font;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 8px;
  }
  strong {
      font-weight: bold;
      padding: 0 4px;
      background: -webkit-linear-gradient(top, transparent 0%, transparent 60%, #fbf36d 60%, #fbf36d 100%);
  }
  i {
      @extend p;
      font-style: italic;
  }
  blockquote {
      @extend p;
      border-left: 3px solid var(--black);
      padding-left: 20px;
      margin-left: -24px;
      padding-bottom: 2px;
      margin: 30px 0px 40px 0;
      font-style: italic;
      b{
        display: block;
        font-size: 18px;
      }
  }
}



//HELPERS
.displace-xl{
  margin-top: 280px;
  @media (max-width: $breakpoint-m){
    margin-top: 120px;
  }
  img{
    margin-top: -160px;
    @media (max-width: $breakpoint-m){
      margin-top: -80px;
      max-width: 80%;
    }
  }
}

.label {
    font-size: 12px;
    font-weight: bold;
    color: var(--grey);
    letter-spacing: 0.83px;
    text-transform: uppercase;
    line-height: 12px;
    display: block;
}
.caption {
    text-align: center;
    font-family: 'Andale Mono', AndaleMono, monaco, consolas, monospace;
    font-weight: 500;
    color: var(--grey);
    font-size: 12px;
    margin-top: 10px;
    letter-spacing: -0.5px;
    line-height: 1.4;
}
.fullwidth {
    width: 100%;
    background-size: cover;
}
.max-container{
  max-width: 1200px;
}



//LAYOUT 50%
.circle-logo{
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 0 auto;
  background: var(--black);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  padding: 24px;
  box-shadow: 0 4px 15px 0 rgba(40,44,53,.06), 0 2px 2px 0 rgba(40,44,53,.08);
  opacity: 0;
  animation-name: project-title-in;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
  img{
    width: 100%;
  }
}

//LAYOUT 50% PROJECTS
.bg-bbva{
  background: #0c2a68;
  .circle-logo{
    background: #072146;
  }
}
.bg-ryanair{
  background: #0d49c0;
  .circle-logo{
    background: #073590;
  }
}
.bg-cepsa{
  background: #1F303E;
  .circle-logo{
    background: #12191f6e;
  }
}
.bg-keepkee{
  background: #1A1E23;
}
.bg-movibeta-soft {
    background: #EFF2F7;
}

//ANIMATIONS
.project-title-in{
  opacity: 0;
  visibility: visible;
  animation-name: project-title-in;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
.project-content-in-top{
  opacity: 0;
  visibility: visible;
  animation-name: project-content-in-top;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
  animation-fill-mode: forwards;
}
.project-content-in-bottom{
  opacity: 0;
  visibility: visible;
  animation-name: project-content-in-bottom;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
  animation-fill-mode: forwards;
}


@keyframes project-title-in {
  0%   {
    opacity: 0;
    transform: rotate3d(-16, 14, -1, 16deg) scale(0.9999);
  }
  100% {
    opacity: 1;
    transform: rotate3d(0,0,0, 0deg), scale(1);
  }
}

@-webkit-keyframes project-title-in {
  0%   {
    opacity: 0;
    -webkit-transform: rotate3d(-16, 14, -1, 16deg), scale(0.9999);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate3d(0,0,0, 0deg), scale(1);
  }
}

//CONTENT
@-webkit-keyframes project-content-in-top {
  0%   {
    opacity: 0;
    -webkit-transform: translateY(-120px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes project-content-in-top {
  0%   {
    opacity: 0;
    transform: translateY(-120px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes project-content-in-bottom {
  0%   {
    opacity: 0;
    -webkit-transform: translateY(120px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes project-content-in-bottom {
  0%   {
    opacity: 0;
    transform: translateY(120px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


//DETAILS
.movibeta-img-rotate{
  margin-top: -36%;
  margin-bottom: -36%;
  transform: rotate(30deg) scale(0.6);
}

//CIRCLES DATA
.circle-wrapper{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.circle-figure {
  max-width: 400px;
  will-change: opacity,transform;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  padding: 0 32px;
}
.circle{
  background-color: #1651f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 220px;
  color: #fff;
  border-radius: 50%;
  font-size: 4.5rem;
  font-weight: 700;
}

.data-rect{
  max-width: 400px;
  will-change: opacity,transform;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  padding: 32px;
  background: hsl(0deg 0% 94%);
  padding: 40px 30px!important;
  border-radius: 32px;
  margin: 8px;
}
.data-num{
  font-size: 4.5rem;
  font-weight: 700;
}
