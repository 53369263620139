:root{
  --blue         : #00f;
  --dark-blue    : #102483;
  --black        : #111112;
  --white        : #fff;
  --pink         : #FFD7B3;
  --soft-pink    : #f9efeb;
  --soft-gold    : #efece6;
  --cyan         : #6AFDEF;
  --yellow       : rgb(255, 199, 0);

  //THEME && UI
  --dark-grey         : #39364f;
  --grey              : #6F7287;
  --soft-grey         : rgb(153, 153, 153);
  --soft-grey-bg      : #fafafa;
  --soft-purple-bg    : #f6f7fd;
  --grey-neutral      : rgba(22,51,0,.078); 

  --primary           : var(--dark-blue);
  --background        : #fff;
  --heading           : var(--black);
  --typo              : var(--black);

}

.cl-white{color:#fff!important}
.cl-black{color:var(--black)!important}
.cl-grey{color:var(--grey)!important}
.bg-soft-grey{background: var(--soft-grey-bg)}
.bg-black{background: var(--black)}
.bg-almost-black{background: hsla(240, 3%, 10%, 1);}

.primary{color: var(--primary);}
