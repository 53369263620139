.section-title{}
.big-header{
  font-size: 116px;
  line-height: 126px;
  font-weight: 700;
  display: inline-block;
  word-break: break-all;
  @media (max-width: $breakpoint-m){
    font-size: 46px;
    line-height: 56px;
  }
}
.big-description {
    font-size: 36px;
    line-height: 54px;
    width: 66.66667%;
    margin-left: 16.66667%;
    @media (max-width: $breakpoint-m){
      margin-left: 0;
      width: 100%;
      font-size: 26px;
      line-height: 44px;
    }
}
