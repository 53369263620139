.snackbar{
  padding: 24px 24px;
  border-radius: 8px;
  font-weight: 700;
  border: 1px solid;
}

.snackbar-info{
  border-color:hsl(220deg 88% 96%);
  background: hsl(220deg 88% 99%);
}