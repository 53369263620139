.notification{
  background: var(--cyan);
  color: var(--black);
  min-height: 48px;
  padding: 12px 0;
}
.notification-message{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
}
