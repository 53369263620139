
::selection {
  color: var(--black);
  background: var(--yellow);
}

.theme-light{
  //background: linear-gradient(#f6f7fd, rgb(255, 247, 239));
  //background: var(--soft-gold);
  background: #fff;
  color: var(--typo);

  h1,h2,h3,h4,h5,h6{
    color: var(--heading);
  }
  .header-logo,
  .header-menu-nav a{
    color: var(--heading);
  }
  a, .link, a.link{
    color: var(--dark-blue);
  }
  .circle-icon svg path{
    stroke:#00f!important;
  }
  .section-cases-item-description{
    color: var(--black);
  }
}


@media (prefers-color-scheme: dark){
  ::selection {
    color: #fff;
    background: var(--blue);
  }
  //theme-light == theme-dark 😱
  html{
    background: var(--black);
  }
  .theme-light{
    background: var(--black);
    color: #A0AABA; //var(--soft-grey);

    h1,h2,h3,h4,h5,h6{
      color: #fff!important;
    }

    .header-logo,
    .header-menu-nav a{
      color: #fff;
    }

    a, .link, a.link{
      color: var(--yellow);
    }
    .circle-icon svg path{
      stroke: var(--yellow)!important;
    }
    footer{
      background: #23262d!important;
    }
    .section-cases-item{
      background: #23262d!important;
    }
    .section-cases-item-description{
      color: #A0AABA;
    }
    .bg-soft-grey{
      background: #23262d!important;
    }
    .project-detail-title span{
      color: #A0AABA;
    }
    .back-button{
      .back-icon{
        background-color: #fff;
      }
      .back-text{
        color: #fff;
      }
    }
    .pill{
      color: #0e17ff!important;
    }
    .project-detail strong{
      background: #0e17ff;
      color: #fff;
    }
    .drawing-icon svg path{
      stroke: #00f;
    }
    .snackbar-info{
      background: #23262d!important;
      border-color: #23262d!important;
    }
    .section-principles {
      background: #23262d!important;
      color: #fff;
      .grid__item{
        background: hsl(227deg 11% 20%);
        color: #A0AABA;
      }
    }
    .big-description{
      color: #A0AABA;
    }
    .project-detail blockquote{
      border-left-color: #A0AABA;
    }
    button {
      background-color: #0e17ff;
    }

    .data-rect{
      background: hsl(227deg 11% 20%);
      color: #A0AABA;
    }
    .data-num{
      color: #fff;
    }
    .button-nav{
      &:hover{
        background: hsl(227deg, 11%, 20%)!important;
      }
    }
    .selected{
      background: hsl(227deg, 11%, 20%)!important;
    }
  }
}

