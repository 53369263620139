* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
  overflow-x: hidden;
}

.app-wrapper {
  @media (max-width: $breakpoint-m){
  }
}
.global{
  @media (max-width: $breakpoint-m){
  }
}
