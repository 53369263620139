a{
  text-decoration: none;
  cursor: pointer;
}
.back-icon{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    flex: none;
    transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
    &:hover{
      transform: scale(1.4);
      transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
    }
}

a[target~="_blank"]{

}

.button{
  padding: 16px 48px;
  background: var(--black);
  border-radius: 4px;
  color: var(--white);
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  font-weight: 800;
  transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
  cursor: pointer;
  &:hover{
    transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
    background: #2b2b2d;
  }
}


//BANNER EMAIL
.button-small-outline{
  border: 2px solid #fff;
  padding: 6px 16px;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
  cursor: pointer;
  color: #FFF!important;
  font-weight: 800;
  &:hover{
    transition: all 0.2s cubic-bezier(0.68,-0.55,0.27,1.55);
    background: #fff;
    color: var(--black)!important;
  }
}

